import {
  isPlatformBrowser,
  NgIf,
  NgOptimizedImage
} from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  effect,
  ElementRef,
  inject,
  input,
  Input,
  OnDestroy,
  PLATFORM_ID,
  signal,
  viewChild,
  WritableSignal,
} from '@angular/core';
import {
  DomSanitizer,
  SafeResourceUrl
} from '@angular/platform-browser';
import Player from '@vimeo/player'

@Component({
  selector: 'app-video',
  standalone: true,
  imports: [ NgIf, NgOptimizedImage ],
  // TODO: See if we can avoid skipping hydration while preventing flashes
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: { ngSkipHydration: 'true' },
  template: `
    @if (!ready()) {
      <img
        alt=""
        priority
        class="object-cover z-50"
        [ngSrc]="safeImageUrl()!"
        sizes="100vw"
        fill="">
    }
    @defer (on idle) {
      <iframe #iframe
              title="video"
              [src]="safeVideoUrl()"
              [height]="position.height"
              [width]="position.width"
              [style.top.px]="position.top"
              [style.left.px]="position.left"
              [style.position]="fill() === 'cover' ? 'absolute' : 'static'"
              allow="autoplay fullscreen"
              allowfullscreen></iframe>
    }
  `
})

/**
 * Video component
 */
export class VideoComponent implements OnDestroy {
  platformId = inject(PLATFORM_ID)
  cdRef = inject(ChangeDetectorRef)
  domSanitizer = inject(DomSanitizer)
  player: Player | null = null;
  position = {
    height: 0,
    width: 0,
    left: 0,
    top: 0
  }
  ratio = 0;

  iframe = viewChild<ElementRef<HTMLIFrameElement>>('iframe');
  ready = signal(false);
  safeVideoUrl: WritableSignal<SafeResourceUrl | null> = signal(null);
  safeImageUrl: WritableSignal<SafeResourceUrl | null> = signal(null);

  private _video: { video: string, background: boolean } | null = null;
  @Input() set video(value: { video: string, background: boolean }) {
    this._video = value;
    this.safeVideoUrl.set(
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `https://player.vimeo.com/video/${value.video}${value.background ?
          '?controls=0&muted=1&loop=1&autopause=0' :
          ''}`
      )
    );
    if (value.background) {
      this.safeImageUrl.set(
        this.domSanitizer.bypassSecurityTrustResourceUrl(`https://vumbnail.com/${value.video}.jpg`));
    } else {
      this.ready.set(true)
    }
  }

  constructor() {
    if (isPlatformBrowser(this.platformId)) {
      effect(() => {
        const url = this.safeVideoUrl();
        const iframe = this.iframe();
        if (iframe && url) {
          setTimeout(() => this.setUpPlayer(iframe.nativeElement).catch(err => console.error(err)), 0);
        }
      });
    }
  }


  fill = input<'cover' | 'contain'>('contain');

  resizeObserver: ResizeObserver | null = isPlatformBrowser(this.platformId) ?
    new ResizeObserver(entries => {
      for (const entry of entries) {
        const element = (entry.target as HTMLElement)
        const { height, width } = element.getBoundingClientRect();
        if (this.fill() === 'cover') {
          this.position = (height / width < this.ratio) ?
            {
              width,
              height: width * this.ratio,
              top: (height - width * this.ratio) / 2,
              left: 0
            } :
            {
              height,
              width: height / this.ratio,
              top: 0,
              left: (width - height / this.ratio) / 2
            };
        } else {
          this.position = {
            width,
            height,
            top: 0,
            left: 0,
          }
        }
      }
      this.cdRef.detectChanges();
    }) :
    null;

  private async setUpPlayer(iframe: HTMLIFrameElement): Promise<void> {
    this.player = new Player(iframe);
    this.player.on('play', () => {
      this.ready.set(true);
    });
    const [ height, width ] = await Promise.all([
      this.player.getVideoHeight(),
      this.player.getVideoWidth()
    ])
    this.ratio = height / width;
    this.resizeObserver?.observe(iframe.parentElement!.parentElement!);

    if (this._video?.background) {
      await this.player.play();
    }
  }

  ngOnDestroy(): void {
    this.player?.pause();
  }
}
